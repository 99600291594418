<template>
  <div class="detail page">
    <div class="content-wrap">
      <div class="title">
        <h2>{{ title }}</h2>
      </div>
      <div class="info">
        <span class="author info-item"> 作者: {{ author }} </span>
        <span class="created_at info-item"> 发布于: {{ created_at }} </span>
      </div>
      <div class="content" v-html="content" />
    </div>
    <div class="qr-wrap">
      <qr-code />
    </div>
  </div>
</template>

<style lang="scss" scoped>
.detail {
  min-height: 100vh;
  position: relative;
  .qr-wrap {
    position: fixed;
    background-color: #ffffff;
    right: 0;
    top: 300px;
    border-radius: 10px;
    overflow: hidden;
  }
  .title {
    text-align: center;
  }
  .content-wrap {
    background-color: #ffffff;
    .info {
      text-align: center;
      margin: 10px;
      .info-item {
        padding: 0 10px;
      }
    }
  }
  .content {
    padding: 20px;
  }
  .title {
    padding: 10px;
    margin: 0 30px;
    color: #f56c6c;
    border-bottom: solid 2px #67c23a;
  }
}
</style>

<script>
import { getOne } from '@/api/article'
import moment from 'moment'
import QrCode from '@/components/QrCode'
export default {
  name: 'Detail',
  components: {
    QrCode
  },
  data() {
    return {
      title: '',
      content: '',
      author: '',
      created_at: ''
    }
  },
  beforeCreate() {
    this.$router.afterEach((to, from, next) => {
      window.scrollTo(0, 0)
    })
  },
  mounted() {
    const id = this.$route.params.id
    getOne(id).then(res => {
      this.title = res.title
      this.content = res.content
      this.author = res.author
      this.created_at = moment(res.created_at).format('YYYY-MM-DD')
    })
  }
}
</script>
