<template>
  <div v-if="qrcode" class="com">
    <img class="wx-qrcode" :src="qrcode" alt="" srcset="">
    <div>公众号: {{ wechat_name }}</div>
  </div>
</template>
<style lang="scss" scoped>
.com {
  text-align: center;
  font-size: 0.8em;
}
.wx-qrcode {
  width: 200px;
  height: 200px;
}
</style>

<script>
import { mapGetters } from 'vuex'

export default {
  computed: {
    ...mapGetters(['qrcode', 'wechat_name'])
  }
}
</script>
